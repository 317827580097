import {paramsEncode} from "@/utils/commonUtils";
import {axios_instance} from "@/utils/axios_instance";
import {GET_JOURNALLIST, GET_MOREJOURNALLIST} from "@/actions/SIMT-SI/SpecialIssue/SIAction";
// This is to get the Sca Journal
export function getJournalListWithName(value, pageNum = 1) {
    return async dispatch => {
        try {
            let params = paramsEncode({
                pageNum: pageNum,
                pageSize: 30,
                journalName:value,
                type:'opt-in-journal-name',
            });

            const res = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                if (pageNum > 1) {
                    dispatch({type: GET_MOREJOURNALLIST, data: data});
                } else {
                    dispatch({type: GET_JOURNALLIST, data: data});
                    dispatch({type: GET_MOREJOURNALLIST, data: []});
                }
            }
        } catch {
        }
    };
}
// This is to get the sca journal list
export function getJournalListWithCode(value, pageNum = 1) {
    return async dispatch => {
        try {
            let params = paramsEncode({
                pageNum: pageNum,
                pageSize: 30,
                journalCode: value,
                type:'opt-in-journal-code',
            });

            const res = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                if (pageNum > 1) {
                    dispatch({type: GET_MOREJOURNALLIST, data: data});
                } else {
                    dispatch({type: GET_JOURNALLIST, data: data});
                    dispatch({type: GET_MOREJOURNALLIST, data: []});
                }
            }
        } catch {
        }
    };
}

export function getJournalListWithCodeAndName(value) {//同时进行两个查询
    return async dispatch => {
        try {
            const valueArr = value.split(/\(|\)/);
            const {name, code} = valueArr.length >= 2 ? {name: valueArr[0], code: valueArr[1]} : {
                name: valueArr[0],
                code: valueArr[0]
            };
            const res1 = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: {
                    type: 'opt-in-journal-code',
                    pageNum: 1,
                    pageSize: 30,
                    journalCode:code,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const res2 = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: {
                    type: 'opt-in-journal-name',
                    pageNum: 1,
                    pageSize: 30,
                    journalName:name,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res1.data.code === 200 && res2.data.code === 200) {
                //两个数组去重、合并
                const result = [...res1.data.data, ...res2.data.data.filter(item => !res1.data.data.some(item2 => item2.journalCode === item.journalCode))];
                dispatch({type: GET_JOURNALLIST, data: result});
            }
        } catch (e) {
            console.log(e);
        }
    };
} //opt-in journal的列表

export function getAllJournalByCodeAndName(value) {
    return async dispatch => {
        try {
            const res = await axios_instance.get('/api/v1/si-service/journals', {
                params: {
                    type: 'JOURNAL',
                    journalName: value,
                    pageNum: 1,
                    pageSize: 30,
                    order: 'asc',
                    orderBy: 'journalName',
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({type: GET_JOURNALLIST, data: res.data.data});
            }
        } catch (e) {
            console.log(e);
        }
    };
}

export function getJournalListWithNameOptIn(value, pageNum = 1) {
    return async dispatch => {
        try {
            let params = paramsEncode({
                pageNum: pageNum,
                pageSize: 30,
                journalName:value,
                type:'opt-in-journal-name',
            });

            const res = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                if (pageNum > 1) {
                    dispatch({type: GET_MOREJOURNALLIST, data: data});
                } else {
                    dispatch({type: GET_JOURNALLIST, data: data});
                    dispatch({type: GET_MOREJOURNALLIST, data: []});
                }
            }
        } catch {
        }
    };
} //opt-in journal的列表
export function getJournalListWithCodeOptIn(value, pageNum = 1) {
    return async dispatch => {
        try {
            let params = paramsEncode({
                pageNum: pageNum,
                pageSize: 30,
                journalCode:value,
                type:'opt-in-journal-code',
            });

            const res = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: params,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const {code,data} = res.data;
            if (code === 200) {
                if (pageNum > 1) {
                    dispatch({type: GET_MOREJOURNALLIST, data: data});
                } else {
                    dispatch({type: GET_JOURNALLIST, data: data});
                    dispatch({type: GET_MOREJOURNALLIST, data: []});
                }
            }
        } catch {
        }
    };
}