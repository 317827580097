import { axios_instance, axios_instance_fileDownload } from "@/utils/axios_instance";
import {
    BATCH_UPDATE_ERROR,
    BATCH_UPDATE_SUCCESS,
    CREATE_JOURNAL,
    Edit_OPTIN_FAIL,
    Edit_OPTIN_SUCCESS,
    EXPORT_CAT_JOURNAL_STATUS,
    GET_CAT_JOURNALS_BY_CODE,
    GET_CAT_JOURNALS_BY_NAME,
    GET_DEVELOPMENTMODE,
    GET_JOURNAL,
    GET_SUBJECT_GROUP_LIST,
    LOAD_ALL_CAT_DATA,
    LOAD_CAT_DATA,
    LOAD_CAT_JOURNAL_FILTER,
    WITHDRAW_OPTIN,
    ERROR_MESSAGE_COLLAPSE
} from "@/actions/SIMT-SI/CatJournal/CATJournalAction";
import { setSnackbarMessageAndOpen } from "@/actions/SnackbarAction";
import { SEVERITIES } from "@/modules/ConfirmSnackBar/CommonSnackBar";
import { downloadFile, getMessageIdFromResponse, paramsEncode, sleep } from "@/utils/commonUtils";
import moment from "moment/moment";
import { GET_JOURNAL_DETAIL } from "@/actions/SIMT-SI/CatJournal/CATJournalDetailAction";
import getAuth from "@/utils/getAuth";

/**
 * 获取journal
 * @param {*} journalCode
 */
export function getJournalByCode(journalCode, pageNum, pageSize) {
    return async dispatch => {
        try {
            var res = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: {
                    type: 'cat-journal-getByCode',
                    pageNum: pageNum ?? 1,
                    pageSize: pageSize ?? 100,
                    journalCode: journalCode,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_JOURNAL, data: res.data.data });
            } else {
                dispatch({ type: GET_JOURNAL, data: 'error' });
            }
        } catch {
            return dispatch({ type: GET_JOURNAL, data: 'error' });
        }
    };
}

/**
 * 获取developmentmode
 * @param {*} journalCode
 */
export function getDevelopmentMode() {
    return async dispatch => {
        try {
            var res = await axios_instance.get('/api/v1/user-service/configs', {
                params:{
                    type : 'Development Model', 
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_DEVELOPMENTMODE, data: res.data.data.map(entry => entry.value)});
            } else {
                dispatch({ type: GET_DEVELOPMENTMODE, data: 'error' });
            }
        } catch {
            return dispatch({ type: GET_DEVELOPMENTMODE, data: 'error' });
        }
    };
}

/**
 * 创建journal
 * @param {*} journalData
 */
export function createJournalByCode(journalData) {
    return async dispatch => {
        try {
            var res = await axios_instance.post(
                '/cat_journal/create',
                {
                    developmentModel: journalData.developmentMode,
                    handlingCeEmail: journalData.handlingCeEmail,
                    journalCode: journalData.journalCode,
                    optInDate: journalData.optInDate,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            if (res.data.code === 200) {
                dispatch({ type: CREATE_JOURNAL, data: 'true' });
            } else {
                dispatch({ type: CREATE_JOURNAL, data: 'error' });
            }
        } catch {
            return dispatch({ type: CREATE_JOURNAL, data: 'error' });
        }
    };
}

/**
 *
 * @param {import("@/pages/CATJournal/Overview/CATTable/CATSearchRow/journal-filter-components/JournalFilterPopup").CatFilterSelectedDTO} props
 * @returns {(dispatch:import("redux").Dispatch) => Promise<void>}
 */
export function getCATJournalList({
    journalCode = null,
    journalName = null,
    status = null,
    optInDate = null,
    optOutDate = null,
    developmentModel = null,
    journalHandlingCe = null,
    scam = null,
    catGroup = null,
    pdPublisher = null,
    pdManager = null,
    pdDirector = null,
    ppPublisher = null,
    ppManager = null,
    ppDirector = null,
    subjectGroup = null,
    businessDivision = null,
    revenueModel = null,
    pageNum = 1,
    pageSize = 50,
    orderField = 'updatedTime',
    orderType = 'DESC',
    keywords = '',
}) {
    const data = { //convert to DTO to filter with backend
        journalCode: journalCode,
        journalName: journalName,
        status: status,
        startOptInDate: optInDate?.start,
        endOptInDate: optInDate?.end,
        startOptOutDate: optOutDate?.start,
        endOptOutDate: optOutDate?.end,
        developmentModel: developmentModel,
        journalHandlingCe: journalHandlingCe,
        scam: scam,
        catGroup: catGroup,
        pdPublisher: pdPublisher,
        pdManager: pdManager,
        pdDirector: pdDirector,
        ppPublisher: ppPublisher,
        ppManager: ppManager,
        ppDirector: ppDirector,
        subjectGroup: subjectGroup,
        businessDivision: businessDivision,
        revenueModel: revenueModel,
        keywords: keywords,
        orderField: orderField,
        orderType: orderType,
        pageNum: pageNum,
        pageSize: pageSize,
        type: 'cat-journal-overview',
    };

    return async dispatch => {
        try {
            const res = await axios_instance.get('/api/v1/si-service/journals/managed', {
                params: data,
                headers: {
                    authorization: localStorage.getItem('authorization') || '',
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: LOAD_CAT_DATA, data: res.data });
            } else {
                dispatch({ type: LOAD_CAT_DATA, data: [] });
            }
        } catch (e) {
            dispatch({ type: LOAD_CAT_DATA, data: [] });
        }
    };
}

export function getCatCodeByFilter({
    journalCode = null,
    journalName = null,
    status = null,
    optInDate = null,
    optOutDate = null,
    developmentModel = null,
    journalHandlingCe = null,
    scam = null,
    catGroup = null,
    pdPublisher = null,
    pdManager = null,
    pdDirector = null,
    ppPublisher = null,
    ppManager = null,
    ppDirector = null,
    subjectGroup = null,
    businessDivision = null,
    revenueModel = null,
    keywords = ''
}) {
    const data = { //convert to dto to filter with backend
        journalCode: journalCode,
        journalName: journalName,
        status: status,
        startOptInDate: optInDate?.start,
        endOptInDate: optInDate?.end,
        startOptOutDate: optOutDate?.start,
        endOptOutDate: optOutDate?.end,
        developmentModel: developmentModel,
        journalHandlingCe: journalHandlingCe,
        scam: scam,
        catGroup: catGroup,
        pdPublisher: pdPublisher,
        pdManager: pdManager,
        pdDirector: pdDirector,
        ppPublisher: ppPublisher,
        ppManager: ppManager,
        ppDirector: ppDirector,
        subjectGroup: subjectGroup,
        businessDivision: businessDivision,
        revenueModel: revenueModel,
        keywords: keywords,
        type : 'cat-journal-overview-code',
    };
    // const data = {
    //   siCode: null,
    //   leadGeFullName: null,
    //   coGeFullName: null,
    //   handlingCe: null,
    //   group: null,
    //   groupNames: null,
    //   journalName: null,
    //   journalRevenueModel: null,
    //   jpm: null,
    //   SubjectGroup: null,
    //   stage: null,
    //   acquiredDateFrom: null,
    //   acquiredDateTo: null,
    //   submissionDeadlineFrom: null,
    //   submissionDeadlineTo: null,
    // };

    return async dispatch => {
        try {
            // new api has problem, change to old api
            const res = await axios_instance.get(
                '/api/v1/si-service/journals/managed',
                {
                    params: data,
                    headers: {
                        authorization: localStorage.getItem('authorization') || '',
                    },
                }
            );
            const { code } = res.data;
            if (code === 200) {
                dispatch({ type: LOAD_ALL_CAT_DATA, data: res.data });
            } else {
                dispatch({ type: LOAD_ALL_CAT_DATA, data: [] });
            }
        } catch (e) {
            dispatch({ type: LOAD_ALL_CAT_DATA, data: [] });
        }
    };
}

/**
 * 将文件导出
 * @param {*} exportType excel或是csv
 * @param {*} keywords 搜索的关键词
 * @returns
 */
export function downloadCAT({ exportType = 'excel', keywords = '' } = {}) {
    let params = {
        type: exportType,
        journalCode: keywords,
    };
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.get('/api/v1/si-service/journals/managed/export', {
                responseType: 'blob',
                params: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (exportType === 'excel') {
                downloadFile(res.data, `CAT Journal ${keywords}.xls`);
            } else {
                downloadFile(res.data, `CAT Journal ${keywords}.csv`);
            }
        } catch (e) {
        }
    };
}

/**
 * 删除对应CAT Journal
 * @param {*} journalCode
 * @returns
 */
export function deleteCATJournal(journalCode, journalName, type) {
    let data = {
        journalCode: journalCode,
    };

    return async dispatch => {
        try {
            let res = await axios_instance.delete('/cat_journal/delete', {
                params: data,
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (type === 1) {
                if (res.data.code === 200) {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.deleteSuccessMsg',
                            { journalName: journalName, journalCode: journalCode },
                            SEVERITIES.success
                        )
                    );
                } else {
                    dispatch(
                        setSnackbarMessageAndOpen(
                            'catJournal.deleteSFailMsg',
                            {},
                            SEVERITIES.error
                        )
                    );
                }
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.deleteSFailMsg',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

/**
 *
 * @param {*} catJournal 为一个对象必须包含journalCode，还有一个需要update的属性：developmentModel，handlingCeEmail，optInDate
 * @returns
 */
export function updateCATJournal(catJournal) {
    return async dispatch => {
        try {
            let res = await axios_instance.put('/api/v1/si-service/journals/managed', catJournal, {
                params:{
                    type: 'singleUpdate',
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });

            if (res.data.code === 200) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'catJournal.updateSuccessMsg',
                        { journalCode: catJournal.journalCode },
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'catJournal.deleteSFailMsg',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.deleteSFailMsg',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

export function editOptIn(data) {
    return async dispatch => {
        try {
            let res = await axios_instance.put(
                `/api/v1/si-service/journals/managed/${data.journalCode}/requests`,
                {
                    action: 'editOptInRequest',
                    ...data,
                    reasonForOptIn: data.reason,
                    siPlan: data.currentSIPipelineAndFutureSIPlan
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            const { code, message } = res.data;
            if (code === 200) {
                dispatch({ type: Edit_OPTIN_SUCCESS, data: message });
            } else {
                dispatch({ type: Edit_OPTIN_FAIL, data: code });
            }
        } catch (e) {
            dispatch(setSnackbarMessageAndOpen(ERROR_MESSAGE_COLLAPSE, {}, SEVERITIES.error));
        }
    };
}

export function updateCATJournalComments(journalCode, comments) {
    let params = {
        action: "updateComments",
        journalCode: journalCode,
        comments: comments,
    };
    return async dispatch => {
        try {
            let res = await axios_instance.put(
                `/api/v1/si-service/journals/managed/${journalCode}/requests`,
                params,
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );

            if (res.data.code === 200) {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'catJournal.updateSuccessMsg',
                        { journalCode: journalCode },
                        SEVERITIES.success
                    )
                );
            } else {
                dispatch(
                    setSnackbarMessageAndOpen(
                        'catJournal.deleteSFailMsg',
                        {},
                        SEVERITIES.error
                    )
                );
            }
        } catch (e) {
            dispatch(
                setSnackbarMessageAndOpen(
                    'catJournal.deleteSFailMsg',
                    {},
                    SEVERITIES.error
                )
            );
        }
    };
}

// old api:Delete /cat_journal/withdrawOptInRequest/{journalCode}
export const withDrawOptIn = journalCode => {
    return async dispatch => {
        try {
            const url = `/api/v1/si-service/journals/managed/${journalCode}/requests`;
            const res = await axios_instance.delete(url, {
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
                params: {
                    type: 'in'
                }
            });
            const { code, message } = res.data;
            if (code === 200) {
                dispatch({ type: WITHDRAW_OPTIN, data: message });
            }
        } catch (e) {
            dispatch(setSnackbarMessageAndOpen(ERROR_MESSAGE_COLLAPSE, {}, SEVERITIES.error));
        }
    };
};
export const CATJournalBatchUpdate = data => {
    return async dispatch => {
        try {
            const url = '/api/v1/si-service/journals/managed';
            const res = await axios_instance.put(url, data, {
                params: {
                    type: 'batchUpdate'
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            const { code, message } = res.data;

            if (code === 200) {
                dispatch({ type: BATCH_UPDATE_SUCCESS, data: message });
            } else {
                const mid = getMessageIdFromResponse(res);
                dispatch(setSnackbarMessageAndOpen(mid, {}, SEVERITIES.error));
                dispatch({ type: BATCH_UPDATE_ERROR, data: code });
                dispatch({ type: BATCH_UPDATE_SUCCESS, data: null });
            }
        } catch (e) {
            dispatch(setSnackbarMessageAndOpen(ERROR_MESSAGE_COLLAPSE, {}, SEVERITIES.error));
        }
    };
};

export function exportCatJournalExcel(journalCodes) {
    var filename = `ScaJournalList_${moment()
        .utc()
        .format('MMDDYYYY_HHmmss')}.xlsx`;
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.post(
                '/api/v1/si-service/journals/managed/export',
                {
                    type: 'excel',
                    journalCode: journalCodes,
                },
                {
                    responseType: 'blob',
                    // params: {
                    //   journalCodes: arrayToString(journalCodes),
                    // },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            downloadFile(res.data, filename);
            dispatch({ type: EXPORT_CAT_JOURNAL_STATUS, data: true });
        } catch (err) {
            dispatch({ type: EXPORT_CAT_JOURNAL_STATUS, data: false });
        }
    };
}

export function exportCatJournalCSV(journalCodes) {
    var filename = `ScaJournalList_${moment()
        .utc()
        .format('MMDDYYYY_HHmmss')}.csv`;
    return async dispatch => {
        try {
            let res = await axios_instance_fileDownload.post(
                '/api/v1/si-service/journals/managed/export',
                {
                    type: 'csv',
                    journalCode: journalCodes,
                },
                {
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                }
            );
            downloadFile(res.data, filename);
            dispatch({ type: EXPORT_CAT_JOURNAL_STATUS, data: true });
        } catch (err) {
            dispatch({ type: EXPORT_CAT_JOURNAL_STATUS, data: false });
        }
    };
}

/**
 * 加载journal的filter选项
 * @param {keyof import("@/pages/CATJournal/Overview/CATTable/CATSearchRow/journal-filter-components/JournalFilterPopup").CatFilterDataState} type
 * @param {string} prefix
 * @return {function(*): Promise<void>}
 */
export function loadCatJournalFilter(type, prefix = '') {
    return async dispatch => {
        await sleep(500);

        try {
            /** @type {string[]} */
            if (
                type === 'status' ||
                type === 'developmentModel' ||
                // type === 'catGroup' ||
                type === 'businessDivision' ||
                type === 'revenueModel' ||
                type === 'subjectGroup'
            ) {
                return;
            }

            if (prefix.trim() === '') {
                dispatch({
                    type: LOAD_CAT_JOURNAL_FILTER,
                    data: {
                        name: type,
                        data: [],
                    },
                });
                return;
            }

            /** @type {import("@/utils/axios-response").AxiosResponse<string[]>} */
            const res = await axios_instance.get('/api/v1/si-service/journals/filter-metadata',
                {
                    params: paramsEncode(
                        {
                            type: "journal",
                            field: type,
                            searchKey: prefix,
                        }
                    ),
                    headers: {
                        authorization: localStorage.getItem('authorization') || '',
                    },
                }
                );

            if (res.data.code === 200) {
                const data = res.data.data;
                dispatch({
                    type: LOAD_CAT_JOURNAL_FILTER,
                    data: {
                        name: type,
                        data: data,
                    },
                });
            }
        } catch (e) {
        }
    };
}

export function getSubjectGroupList() {
    return async dispatch => {
        let url = '/api/v1/si-service/journals/filter-metadata';
        try {
            const res = await axios_instance.get(url,
                {
                    params: {
                        type: "subjectGroup",
                        searchKey: "",
                        pageNum: 1,
                        pageSize: 10000
                    },
                    headers: {
                        authorization: localStorage.getItem('authorization'),
                    },
                });
            if (res.data.code === 200) {
                dispatch({ type: GET_SUBJECT_GROUP_LIST, data: res.data });
            } else {
                dispatch({ type: GET_SUBJECT_GROUP_LIST, data: null });
                // dispatch(
                //   setSnackbarMessageAndOpen('email.showError', {}, SEVERITIES.error)
                // );
            }
        } catch (err) {
            dispatch({ type: GET_SUBJECT_GROUP_LIST, data: null });
        }
    };
}

export const getCATJournalsByCode = (
    code = '',
    pageNum = 1,
    pageSize = 100
) => {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/si-service/journals/managed/codes-and-names', {
                params: {
                    journalCode: code,
                    pageNum: pageNum,
                    pageSize: pageSize,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_CAT_JOURNALS_BY_CODE, data: res.data });
            } else {
                dispatch({ type: GET_CAT_JOURNALS_BY_CODE, data: null });
            }
        } catch (err) {
            dispatch({ type: GET_CAT_JOURNALS_BY_CODE, data: null });
        }
    };
};
export const getCATJournalsByName = (
    name = '',
    pageNum = 1,
    pageSize = 100
) => {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/si-service/journals/managed/codes-and-names', {
                params: {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    journalName: name,
                },
                headers: {
                    authorization: localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_CAT_JOURNALS_BY_NAME, data: res.data });
            } else {
                dispatch({ type: GET_CAT_JOURNALS_BY_NAME, data: null });
            }
        } catch (err) {
            dispatch({ type: GET_CAT_JOURNALS_BY_NAME, data: null });
        }
    };
};

export function getJournalDetail(journalCode) {
    return async dispatch => {
        try {
            let res = await axios_instance.get('/api/v1/si-service/journals', {
                params: {
                    journalCodes: journalCode
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'authorization': localStorage.getItem('authorization'),
                },
            });
            if (res.data.code === 200) {
                dispatch({ type: GET_JOURNAL_DETAIL, data: res.data });
            }

        } catch (e) {
            console.log(e);
        }
    };
}
/**
 * @param {import("./type").submitOptInCheckJournalRequest} params
 */
export const GetAllManagedJournals = (params) => axios_instance.get('/api/v1/si-service/journals/managed',
    {
        params,
        headers: {
            authorization: getAuth() ?? '',
        },
    }
).then((/**@type {import("@/utils/axios-response").AxiosResponse<import("./type").submitOptInCheckJournalResponse>}*/e) => e);