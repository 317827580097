/*
 * @Author: jojo 903497174@qq.com
 * @Date: 2023-07-21 16:10:58
 * @LastEditors: jojo 903497174@qq.com
 * @LastEditTime: 2023-09-16 18:21:44
 * @FilePath: \simt_front\src\utils\axios_instance.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**
 * @author:Liu Xin
 */

import axios from 'axios';
import Config from '../configs/Config';
import { getLocalStorage } from '@/utils/localStorageHandler';

export const baseURLLink = Config.API_URL;

export const cookieSet = () => {
  var ACookie = getLocalStorage('isAcceptCookie');
  if (ACookie !== undefined && ACookie) {
    // console.log(ACookie)
    return ACookie;
  } else {
    return false;
  }
};

const isCookie = cookieSet();

const axios_instances = Array(4)
  .fill(0)
  .map(() => {
    const instance = axios.create({
      baseURL: baseURLLink,
      crossDomain: true,
    });

    instance.interceptors.request.use(config => {
      if (!config.headers['Authorization'] && !config.headers['authorization']) {
          config.headers.authorization = localStorage.getItem('authorization') ?? '';
      }
      config.withCredentials = isCookie;
      return config;
    });

    instance.interceptors.response.use(
      res => {
        // 请求成功对响应数据做处理
        if (res.data.code === 500 && res.data.message === 'Invalid JWT') {
          localStorage.removeItem('authorization');
          window.location.href = '/simt/login';
        }
        // 该返回的数据则是axios.then(res)中接收的数据
        return res;
      },
      err => {
        // 在请求错误时要做的事儿
        if (err.response) {
          // 诸如timeout等情况时response是undefined，会引起另一个error
          if (err.response.status === 401) {
            if (err.response?.data?.data) {
              window.location.href = err.response.data.data;
            } else {
              localStorage.removeItem('authorization');
              window.location.href = '/simt/login';
            }
          } else if (err.response.status === 403) {
            //跳回首页
            window.location.href = '/simt/auth/index';
          }
          // 在err.response合法时才能够这么返回
          // 该返回的数据则是axios.catch(err)中接收的数据
          return Promise.reject(err.response);
        } else {
          return Promise.reject(err); // 返回err本身
        }
      }
    );
    return instance;
  });
axios_instances[0].defaults.timeout = 20000;
axios_instances[1].defaults.timeout = 40000;
axios_instances[2].defaults.timeout = 1000000;
axios_instances[3].defaults.timeout = 10000000;

export const axios_instance = axios_instances[0];
export const axios_instance_l = axios_instances[1];
export const axios_instance_fileDownload = axios_instances[2];
export const axios_instance_fileUpload = axios_instances[3];
